import React, { useState } from "react";
import MusicPlayer from "./MusicPlayer";
import "./ProfilePage.css"; // Import your CSS styles

const ProfilePage = () => {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <div className="outer-container">
      <div className="photo-container right-photo">
        {/* Right Container Photos */}
        <img src="https://i.imgur.com/UWrYeqc.jpg" alt="Left Image 1" />
        <img src="https://i.imgur.com/0mfYuad.png" alt="Left Image 4" />
        <img src="https://i.imgur.com/e8TXNG1.png" alt="Left Image 5" />
        <img src="https://i.imgur.com/jl7Gf58.jpg" alt="Left Image 6" />
        <img src="https://i.imgur.com/phhD6ty.png" alt="Left Image 7" />
      </div>
      <div className="master-container">
        <header className={`main-header ${menuVisible ? "menu-visible" : ""}`}>
          {/* Mobile Menu Button */}
          <button className="menu-button" onClick={toggleMenu}>
            ☰ Menu
          </button>

          <nav className={`navbar ${menuVisible ? "menu-visible" : ""}`}>
            <div className="navbar-center">
              <ul>
                <li>
                  <a href="https://www.youtube.com/watch?v=hRGIrrjuLYA">
                    My Favorite Song
                  </a>
                  <MusicPlayer songUrl={"audio/websitemusic.mp3"} />
                </li>
                <li>
                  <a href="https://twitter.com/TokenYLM">Twitter</a>
                </li>
                <li>
                  <a href="https://t.me/+FXRwFFhgLmZiNzk0">Telegram</a>
                </li>
                <li>
                  <a href="https://dexscreener.com/solana/ebgelf2bbaukhuxuo2cycauxtr3a2klhno4leh87cjnc">
                    Dexscreener
                  </a>
                </li>
              </ul>
            </div>
            <div className="navbar-brand">
              <a href="#">yilongma.xyz</a>
            </div>
          </nav>
        </header>

        <div className="page-content-container">
          <aside className="profile-sidebar">
            <header>
              <h1>
                <a>Yi Long Ma $LONG</a>
              </h1>
            </header>

            <div className="profile-picture-container">
              <img
                className="profile-pic"
                src="https://i.imgur.com/fHZAdJs.jpeg"
                alt="A profile picture of Yi Long Ma, the Chinese Yi Long Ma."
              />
              <ul className="gender-age">
                <li>Male</li>
                <li>52 years old</li>
                <li>Shanghai, China</li>
                <li>Single</li>
              </ul>

              <ul className="last-login">
                <li>Last Login:</li>
                <li>Today</li>
              </ul>

              <p className="pics-videos">
                View My:{<br />}
                <a href="https://t.me/+FXRwFFhgLmZiNzk0">
                  <b>Telegram</b>
                </a>{" "}
                |{" "}
                <a href="https://twitter.com/TokenYLM">
                  <b>Twitter</b>
                </a>
              </p>
            </div>
            <section className="interests">
              <table className="sidebar-table">
                <caption>
                  <h2 className="sidebar-table-h2">Yi Long Ma's Interests</h2>
                </caption>
                <tbody>
                  {
                    <a>
                      1. Interstellar Meme Travel <br /> 2. Cryptocurrency
                      Comedy Club <br /> 3. Anti-Gravity Yoga
                    </a>
                  }
                </tbody>
              </table>
            </section>

            <section className="details">
              <table className="sidebar-table">
                <tbody>
                  {
                    <a>
                      Education, Blockchain, Philanthropy, Comedy, Tech
                      Innovation, Culture
                    </a>
                  }
                </tbody>
                <caption>
                  <h2 className="sidebar-table-h2">
                    Yi Long Ma's Raid Community
                  </h2>
                </caption>
                <tbody>
                  {
                    <a>
                      DM me on Twitter if you want to partake in our raiding
                      group <a href="https://twitter.com/TokenYLM">HERE!</a> !
                    </a>
                  }
                </tbody>
              </table>
            </section>
          </aside>

          <div className="main">
            <section className="user-network">
              <header>
                <h2>Elon Musk is in your extended network</h2>
              </header>
            </section>

            <section className="blurbs">
              <header className="main-section-header">
                <h2 className="main-section-h2">Yi Long Ma's Blurbs</h2>
              </header>
              <h3>
                <a>About me</a>
              </h3>
              <p>
                <em className="bio">
                  Hi everyone I’m Yi Long Ma. I come to spread love and
                  happiness to everybody. <br /> <br />
                  Money? No! I like dogga coins and monkey. <br />I love you.{" "}
                  <br />
                  <br />
                  $long <br />
                  <br />
                  BxJQ3HnbjrnEwHLPHp4xDWdQ6Xf3tQJy62zR7aFK1o7M
                  <br /> <br /> &hearts;
                </em>
              </p>
              <iframe
                width="410"
                height="230"
                src="https://youtube.com/embed/NA7HBViaBXo?si=JqGeKayX0g9johLl"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <br />
              <br />
              <h3>
                <a>Who I'd like to meet:</a>
              </h3>
              <em>Elon Musk, Jeff Bezos, Oprah Winfrey && greg16676935420</em>
            </section>

            <section className="friends">
              <header className="main-section-header">
                <h2 className="main-section-h2">Yi Long Ma's Friend Space</h2>
              </header>
              <p>
                <b>
                  Yi Long Ma has{" "}
                  <span className="focus-highlight">42069000</span> Friends.
                  <a href="https://solscan.io/token/BxJQ3HnbjrnEwHLPHp4xDWdQ6Xf3tQJy62zR7aFK1o7M#holders">
                    View All of Yi Long Ma's Friends
                  </a>
                </b>
              </p>
              <section className="friends">
                <header className="main-section-header">
                  <h2 className="main-section-h2">Top Friends</h2>
                </header>
                <div className="friend-pic-container">
                  <div className="friend">
                    <img
                      src="https://yellowhammernews.com/wp-content/uploads/2021/04/Elon-Musk-SpaceX.jpg"
                      alt="Elon Musk"
                      style={{ width: "250px", height: "150px" }}
                    />
                    <p>Elon Musk</p>
                  </div>
                  <div className="friend">
                    <img
                      src="https://i.imgur.com/0knUSzC.png"
                      alt="My Dog"
                      style={{ width: "250px", height: "150px" }}
                    />
                    <p>My Dog</p>
                  </div>
                  <div className="friend">
                    <img
                      src="https://i.imgur.com/LXmMWra.png"
                      alt="Myself"
                      style={{ width: "250px", height: "150px" }}
                    />
                    <p>Myself</p>
                  </div>
                  <div className="friend">
                    <img
                      src="https://i.imgur.com/VERi16t.png"
                      alt="You"
                      style={{ width: "250px", height: "150px" }}
                    />
                    <p>You</p>
                  </div>
                </div>
              </section>

              <p className="friends-list-link"></p>
            </section>
          </div>
        </div>

        <footer className="footer">
          <nav>
            <ul>{/* Footer navigation links */}</ul>
          </nav>
          <small>©2003-2006 YiLongMa.xyz. All Rights Reserved.</small>
        </footer>
      </div>
      <div className="photo-container right-photo">
        {/* Right Container Photos */}
        <img src="https://i.imgur.com/UWrYeqc.jpg" alt="Left Image 1" />
        <img src="https://i.imgur.com/0mfYuad.png" alt="Left Image 4" />
        <img src="https://i.imgur.com/e8TXNG1.png" alt="Left Image 5" />
        <img src="https://i.imgur.com/jl7Gf58.jpg" alt="Left Image 6" />
        <img src="https://i.imgur.com/phhD6ty.png" alt="Left Image 7" />
      </div>
    </div>
  );
};

export default ProfilePage;
