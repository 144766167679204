import React, { useState, useRef } from "react";
import playImg from "./photos/play-button.svg";
import pauseImg from "./photos/pause.svg";
import songUrl from "./audio/websitemusic.mp3";

const MusicPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const handlePlay = () => {
    setIsPlaying(true);
    audioRef.current.play();
  };

  const handlePause = () => {
    setIsPlaying(false);
    audioRef.current.pause();
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
    audioRef.current.currentTime = 0;
  };

  return (
    <div className="links">
      <audio ref={audioRef} src={songUrl} onEnded={handleAudioEnded} />
      <div>
        {isPlaying ? (
          <div onClick={handlePause} className="music-buttons">
            <img src={pauseImg} alt="Pause" className="link" />
          </div>
        ) : (
          <div onClick={handlePlay} className="music-buttons">
            <img src={playImg} alt="Play" className="link" />
          </div>
        )}
      </div>
    </div>
  );
};

export default MusicPlayer;
